const ANGLELIST = [30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 110, 120];
const ANTENALIST = ['PTP', 'PTMP'];
const COMPASS = ["N", "E", "S", "W", "NE", "NW", "SW", "ES"];
const KILOESPERPIXEL = [118.62609563314751, 59.31304781657376, 29.65652390828688, 14.82826195414344, 7.41413097707172, 3.70706548853586, 1.85353274426793, 0.926766372133965, 0.4633831860669825, 0.23169159303349124, 0.11584579651674562, 0.05792289825837281, 0.028961449129186405, 0.014480724564593202, 0.007240362282296601, 0.0036201811411483006, 0.0018100905705741503, 0.0009050452852870752, 0.0004525226426435376, 0.0002262613213217688];
const COLORS = [
  [
    "#d44539",
    "#c82361",
    "#862bab",
    "#5d3db2"
  ],
  [
    "#4652b1",
    "#5995f0",
    "#5fa7f1",
    "#69bbd2"
  ],
  [
    "#549587",
    "#73ad57",
    "#9cc255",
    "#d0db4b"
  ],
  [
    "#f9ea4f",
    "#efc02f",
    "#e79723",
    "#df582a"
  ],
  [
    "#70564a",
    "#9d9d9d",
    "#627683",
    "#000000"
  ],
]

export {
  ANGLELIST,
  ANTENALIST,
  COMPASS,
  KILOESPERPIXEL,
  COLORS,
}